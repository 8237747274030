#articles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100dvh - 130px);
    margin: 25px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 0 !important;

    .article-loader {
        height: 120px;
        width: 120px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin: auto;
        transform: translateY(-50%);
    }

    input[type='checkbox'] {
        outline: none !important;
        transform: scale(1.2);
        accent-color: rgb(1, 136, 107);
    }

    > .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: fit-content;
        padding: 10px 20px;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #F1F1F1;

        > div {
            display: flex;
            align-items: center;
            margin: 10px 0;

            p {
                font-family: "Poppins";
                white-space: nowrap;
                margin-left: 25px;
                transition: 100ms;
                cursor: pointer;

                &.clickable {
                    &.active { color: rgb(2, 146, 194); }
                    &.green { color: rgb(5, 170, 74); }
                }
            }

            .checkbox {
                display: flex;
                align-items: center;
            }

            .filters {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .filter {
                    position: relative;
                    display: flex;
                    align-items: center;
                    border: 2px solid rgba(128, 128, 128, 0.185);
                    border-radius: 12px;
                    width: 100%;

                    input {
                        font-family: "Poppins";
                        font-weight: 500;
                        width: 100%;
                        color: black;
                        outline: none;
                        padding: 5px 10px 5px 30px;
    
                        &::placeholder { color: gray; }
                    }

                    svg {
                        position: absolute;
                        left: 7px;
                        color: gray;
                    }
                }

                .selection-container {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    margin-right: 20px;
                    width: 300px;
    
                    button {
                        font-family: "Poppins";
                        color: rgb(66, 66, 66);
                        font-weight: 500;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        white-space: nowrap;
                        width: 100%;
                        padding: 5px 50px 5px 10px;
                        border: 2px solid rgba(128, 128, 128, 0.185);
                        border-radius: 12px;
    
                        svg {
                            margin-right: 5px;
                            font-size: 20px;
                            color: var(--dark-orange);
                        }
    
                        .down-arrow {
                            position: absolute;
                            color: #c6c6c6;
                            right: 5px;
                            width: 20px;
                        }
                    }
    
                    >div {
                        position: relative;
                    }
                }
            }

            .pagination {
                display: flex;
                align-items: center;

                span {
                    font-family: "Roboto Condensed";
                    margin-right: 15px;
                }
                
                select {
                    font-family: "Poppins";
                    border-radius: 6px;
                    border: 1px solid rgb(214, 214, 214);
                    padding: 2px 5px;
                    margin: 0 20px;
                }
            }
        }

        .mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 10px 0;

            @media only screen and (max-width: 1620px) {
                > .filters {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    > .filter {
                        margin-right: 0;

                        input {
                            width: 100%;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 825px) {
            > div {
                &:first-child {
                    width: 100%;
                    justify-content: space-between;
                }
                &:nth-child(2) {
                    width: 100%;
                    justify-content: center;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            > div {
                &.mobile .filters {
                    flex-direction: column;

                    > div {
                        width: 100%;
                        margin-bottom: 5px;

                        &.types .selection-container {
                            margin-right: 0;
                            width: 100%;
                        }
                    }
                }

                p {
                    margin-left: 20px;
                }
            }
        }

        @media only screen and (max-width: 520px) {
            padding: 0 10px;

            > div {
                .checkbox {
                    width: 100%;
                    justify-content: center;

                    p {
                        width: fit-content !important;
                        margin-left: 10px;
                    }
                }

                .pagination {
                    select {
                        margin: 0 10px;
                    }
                }

                > p.clickable {
                    display: none;
                }
            }
        }
    }

    > .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        .empty {
            font-family: "Poppins";
            font-size: 20px;
            width: 100%;
            text-align: center;
            color: rgb(59, 59, 59);
            margin-top: 30px;

            a { color: var(--orange); }
        }

        .loader {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 10%;
            }
        }

        .article {
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #F1F1F1;
            background-color: white;

            &:hover {
                background-color: rgb(250, 250, 250);
            }

            &:nth-child(odd) {
                background-color: rgb(252, 252, 252);

                &:hover {
                    background-color: rgb(248, 248, 248);
                }
            }

            > div {
                &.checkbox {
                    padding: 0 5px 0 20px;
                }

                &.container {
                    display: flex;
                    flex-direction: column;
                    padding: 10px 20px;
                    width: 100%;
                    cursor: pointer;

                    .title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
    
                        h3 {
                            font-family: "Roboto Condensed";
                            color: #000;
                            font-size: 25px;
                            font-weight: 600;
                            white-space: nowrap;
                        }
    
                        .keywords {
                            display: flex;
                            justify-content: flex-end;
                            flex-wrap: wrap;
                            padding: 10px 0 10px 20px;
    
                            span {
                                display: block;
                                background: var(--orange);
                                color: white;
                                margin-bottom: 5px;
                                margin-right: 5px;
                                padding: 2px 5px;
                                border-radius: 5px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                max-width: 300px;
                            }
                        }
                    }
    
                    .content {
                        padding: 5px 0 15px 0;
    
                        p {
                            color: #303030;
                            font-family: "Poppins";
                            font-size: 17px;
                            font-weight: 400;
                        }
                    }
    
                    .informations {
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;

                        &, > div { display: flex; }
    
                        .info {
                            font-family: "Roboto Condensed";
                            background-color: #252525;
                            color: white;
                            margin-right: 10px;
                            margin-bottom: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 16px;
                            line-height: 20px;
                            padding: 4px 10px;
                            border-radius: 10px;
                            transition: 200ms;
                            white-space: nowrap;
    
                            &:hover {
                                background-color: #3a3a3a;
                            }

                            &.wp {
                                background: linear-gradient(80deg, rgb(5, 170, 74), rgb(4, 122, 53));
                            }
    
                            b {
                                font-weight: 600;
                                margin: 0 5px;
                            }
    
                            .brand {
                                font-size: 17px;
                                margin-right: 5px;
                                color: white;
                            }
                        }
    
                        .date {
                            font-family: "Roboto Condensed";
                            color: gray;
                            text-align: end;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 850px) {
            .article {
                > div.container {
                    .title {
                        flex-direction: column;
                        align-items: flex-start;

                        .keywords {
                            justify-content: flex-start;
                            margin-top: 5px;
                            padding: 0;
                        }
                    }

                    .informations {
                        > div {
                            flex-wrap: wrap;

                            .info {
                                font-size: 14px;
                                padding: 2px 7px;
                                margin-right: 3px;
                                border-radius: 4px;
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 768px) {
            .article {
                .content {
                    p {
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        @media only screen and (max-width: 520px) {
            .article {
                > div.checkbox {
                    padding: 0 10px;
                }

                > div.container {
                    padding: 10px;

                    .content {
                        padding: 5px 0;
                    }

                    .title {
                        .keywords > div span {
                            max-width: 200px;
                        }
                    }
                }
            }
        }
    }

    .bottom {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #F1F1F1;
        padding: 0 10px;

        a {
            font-family: "Roboto Condensed";
            font-weight: 500;
            font-size: 17px;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;

            svg {
                margin-right: 5px;
            }

            span {
                margin-left: 0;
            }
        }

        .total {
            font-family: "Roboto Condensed";
            white-space: nowrap;
            font-size: 17px;
            margin-left: 5px;
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;

            .data {
                font-family: "Roboto Condensed";
                font-size: 17px;
                margin-left: 5px;
                margin-right: 10px;
            }

            button {
                font-family: "Roboto Condensed";
                color: #FFF;
                font-weight: 500;
                padding: 6px 20px;
                margin-left: 10px;
                border-radius: 10px;
                min-width: 160px;
                font-size: 18px;
                background: var(--orange);
    
                &:hover {
                    background: var(--dark-orange);
                }
    
                &:disabled {
                    background-color: rgba(255, 85, 0, 0.476);
                    cursor: default;
                }
    
                &.blue {
                    background: rgb(2, 146, 194);
    
                    &:hover {
                        background: rgb(1, 118, 158);
                    }
    
                    &:disabled {
                        background-color: rgba(3, 112, 149, 0.326);
                        cursor: default;
                    }
                }

                &.red {
                    background: red;
    
                    &:hover {
                        background: rgb(204, 2, 2);
                    }
    
                    &:disabled {
                        background-color: rgb(252, 143, 167);
                        cursor: default;
                    }
                }

                svg { display: none; }
            }

            .loader {
                height: 30px;
                margin-left: 10px;
            }
        }

        @media only screen and (max-width: 900px) {
            > div {
                button {
                    min-width: fit-content;
                    padding: 6px;
    
                    span { display: none; }
                    svg { display: block; }
                }
            }
        }

        @media only screen and (max-width: 500px) {
            a {
                border-radius: 50%;
                height: 30px;
                width: 30px;
                min-width: 30px;
                background-color: var(--orange);
                color: white;

                span { display: none; }
                svg { margin: 0; }
            }
        }
    }

    #article {
        height: 100%;
        height: calc(100dvh - 185px);
        overflow-y: auto;

        .featured-image {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px;
            border-top: 1px solid #F1F1F1;

            .text {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                h3 {
                    font-family: "Staatliches";
                    font-size: 40px;
                    font-weight: 500;
                }
    
                a {
                    font-family: "Roboto Condensed";
                    font-size: 18px;
                    text-decoration: underline;
                    color: red;
                }
            }

            img {
                width: 100%;
                border-radius: 15px;
                filter: drop-shadow(0 0 2px rgb(175, 175, 175));
            }
        }

        @media only screen and (max-width: 768px) {
            .featured-image {
                padding: 10px;

                .text {
                    h3 {
                        font-size: 23px;
                        margin-right: 5px;
                    }

                    a {
                        font-size: 14px;
                        text-align: end;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        height: calc(100dvh - 120px);
    }

    @media only screen and (max-width: 768px) {
        height: calc(100dvh - 100px);
    }

    @media only screen and (max-width: 600px) {
        margin: 15px;
        height: calc(100dvh - 130px);
    }

    @media only screen and (max-width: 500px) {
        #article { height: calc(100dvh - 125px) }
    }
}