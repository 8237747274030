.alert-box {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    font-family: "Roboto Condensed";
    font-size: 18px;

    svg {
        min-width: 30px;
        font-size: 30px;
        margin-right: 10px;
    }

    &.infobox {
        color: #004085;
        background: rgba(80, 159, 255, 0.467);
        border: 1px solid #00408520;

        svg {
            color: #003cff;
        }
    }

    &.warnbox {
        color: #1c1c1c;
        background: rgb(255, 164, 16);
        border: 1px solid #85350020;

        svg {
            color: #1c1c1c;
        }
    }

    @media only screen and (max-width: 500px) {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;

        svg {
            margin: 0 0 10px 0;
        }
    }
}