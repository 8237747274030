#websites {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 130px);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    margin: 25px;
    padding: 0 !important;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 170px;
        padding: 0 20px;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #F1F1F1;

        .add-website {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            margin-top: 0px;
            padding: 20px 0;
            max-height: 400px;
            overflow-y: auto;

            .form {
                display: flex;
                justify-content: space-between;

                .form-section {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 33%;
                    padding: 0 10px;
    
                    &:nth-child(1) {
                        padding-left: 0;
                    }
                    &:nth-child(3) {
                        padding-right: 0;
                    }
    
                    .text {
                        display: flex;
                        flex-direction: column;
    
                        label {
                            font-family: "Roboto Condensed";
                            font-size: 18px;
                        }
    
                        small {
                            color: rgb(77, 77, 77);
    
                            a {
                                color: var(--dark-orange);
                            }
                        }
                    }
    
                    .input {
                        position: relative;
    
                        &:hover {
                            svg {
                                color: var(--dark-orange);
                            }
                        }
    
                        svg {
                            position: absolute;
                            top: 18px;
                            left: 10px;
                            color: gray;
                        }
    
                        input {
                            font-family: "Poppins";
                            outline-color: var(--orange);
                            width: 100%;
                            padding: 5px 10px 5px 30px;
                            border-radius: 7px;
                            margin-top: 10px;
                            border: 2px solid rgb(240, 240, 240);
                            // box-shadow: 0px 0px 3px -1px rgba(77, 77, 77, 0.356);
                        }
                    }
                }
            }

            .button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                > img { height: 30px; }

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: "Roboto Condensed";
                    color: #FFF;
                    font-weight: 500;
                    padding: 6px 20px;
                    border-radius: 10px;
                    min-width: 200px;
                    font-size: 18px;
                    margin: 0;
                    background: var(--orange);
    
                    &:hover {
                        background: var(--dark-orange);
                    }
                }
            }
        }

        @media only screen and (max-width: 1300px) {
            height: fit-content;

            .add-website {
                .form {
                    margin-bottom: 20px;
                }
            }
        }

        @media only screen and (max-width: 800px) {
            .add-website {
                .form {
                    flex-direction: column;

                    > .form-section {
                        width: 100%;
                        padding: 10px 0;
                    }
                }
            }
        }

        @media only screen and (max-width: 500px) {
            padding: 10px;
            padding-right: 0;

            .add-website {
                padding: 0 10px 0 0;

                .button {
                    button {
                        width: 100%;
                        min-width: 100%;
                    }
                }
            }
        }
    }

    .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .websites {
            display: flex;
            flex-direction: column;
            width: 100%;

            .empty-text {
                font-family: "Poppins";
                font-size: 20px;
                text-align: center;
                color: rgb(59, 59, 59);
                margin-top: 30px;
    
                a { color: var(--orange); }
            }

            .website {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 10px 20px;
                border-bottom: 1px solid #F1F1F1;
                background-color: white;

                &:hover {
                    background-color: rgb(250, 250, 250);
                }

                &:nth-child(odd) {
                    background-color: rgb(252, 252, 252);

                    &:hover {
                        background-color: rgb(248, 248, 248);
                    }
                }

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    > div {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-right: 20px;
    
                        img {
                            height: 40px;
                            width: 40px;
                            border-radius: 2px;
                        }
    
                        p {
                            font-family: "Poppins";
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            color: rgb(59, 59, 59);
                        }
                    }

                    > a {
                        font-family: "Roboto";
                        color: var(--dark-orange);
                        text-align: end;
                        font-size: 23px;
                    }
                }

                @media only screen and (max-width: 500px) {
                    width: 100%;
                    justify-content: space-between;
                    padding: 0;

                    > div {
                        padding: 10px;
                        width: 100%;

                        > div {
                            margin-right: 0;

                            &.favicon {
                                margin-right: 5px;

                                img {
                                    height: 25px;
                                    width: 25px;
                                }
                            }

                            a {
                                word-break: break-all;
                            }
                        }

                        &:last-child {
                            width: 50px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        height: calc(100dvh - 120px);
    }

    @media only screen and (max-width: 768px) {
        height: calc(100dvh - 100px);
    }

    @media only screen and (max-width: 600px) {
        margin: 15px;
        height: calc(100dvh - 130px);
    }
}