.plain-container {
    padding: 20px;

    h2, h3, h4, h5, h6, p {
        font-family: "Roboto" !important;
        margin: 10px 0 !important;
        text-align: start !important;
    }

    h1 { margin: 0 0 20px 0 !important; }
    h2 {
        font-size: 30px !important;
        font-weight: 700 !important;
    }
    h3 {
        font-size: 24px !important;
        font-weight: 700 !important;
    }

    ul, ol { margin: 30px 0 !important; }

    .content {
        table {
            width: 100%;

            tr {
                &:nth-child(odd) {
                    background-color: rgb(245, 245, 245);
                }

                td {
                    padding: 30px 10px;

                    &, p {
                        text-align: center;
                    }
                }

                th { padding: 10px; }
            }
        }

        summary {
            cursor: pointer;
        }

        img {
            width: 40vw;
            display: flex;
            margin: 0 auto;
            border-radius: 16px;

            @media only screen and (max-width: 1100px) {
                width: 70vw;
            }

            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }
}