#publish-status-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.45);

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: white;
        box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 10px;
        min-height: 300px;
        height: 40%;
        width: 50%;

        .close {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 22px;
            cursor: pointer;
        }

        .spinner {
            width: 40%;
            margin-top: -20px;
        }

        > p, span.progress {
            font-family: "Poppins";
            display: block;
            font-size: 23px;
            text-align: center;
            margin-top: 20px;

            &.success-text {
                margin-bottom: 20px;
            }

            &.span.progress {
                font-size: 20px;

                b {
                    font-size: 23px;
                    font-weight: 600;
                }
            }
        }

        a {
            margin-top: 10px;
            color: var(--dark-orange);
            font-size: 18px;
        }

        @media only screen and (max-width: 768px) {
            width: 90vw;
            height: 90vh;
        }
    }
}