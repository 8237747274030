.generate-container {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    max-height: calc(100dvh - 130px);
    max-width: 100%;
    min-width: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

    >div {
        height: calc(100dvh - 185px);
        overflow-y: auto;
        
        &::-webkit-scrollbar-track {
            background: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background: #e2e2e2;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #bebebe;
        }

        &.input, &.loading {
            width: 40%;
            min-width: 40%;
            display: flex;
            flex-direction: column;
            padding: 20px;
            border-right: 1px solid #F1F1F1;

            h2 {
                font-family: "Staatliches";
                font-weight: 500;
                font-size: 30px;
                margin-bottom: 10px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    font-family: "Roboto Condensed";
                    font-weight: 500;
                    font-size: 17px;
                    color: var(--dark-orange);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        margin-right: 10px;
                    }
                }

                @media only screen and (max-width: 450px) {
                    font-size: 25px;

                    a {
                        border-radius: 50%;
                        height: 30px;
                        width: 30px;
                        min-width: 30px;
                        background-color: var(--orange);
                        color: white;

                        span { display: none; }
                        svg { margin: 0; }
                    }
                }
            }

            span {
                font-family: "Roboto Condensed";
                font-size: 20px;

                small {
                    color: gray;
                    font-size: 12px;
                }
            }

            small {
                font-family: "Poppins";
                color: gray;
                font-size: 14px;
                font-weight: 300;
                margin-top: 5px;

                >span, >a {
                    font-family: "Poppins";
                    font-size: 14px;
                    color: var(--dark-orange);
                    font-weight: 500;
                }

                &.content-words {
                    text-align: end;
                    font-size: 13px;
                    margin-top: 5px;
                }
            }

            textarea {
                resize: vertical;
                outline-color: var(--orange);
                min-height: 350px;
                padding: 10px;
                border: 2px solid rgba(128, 128, 128, 0.053);
                border-radius: 10px;
                margin-top: 10px;
            }

            >div {
                position: relative;
                margin-bottom: 20px;
                margin-top: 10px;

                &:first-child { margin-top: 0; }
                &:last-child { margin-bottom: 0; }
            }

            .text {
                display: flex;
                flex-direction: column;

                >input {
                    outline-color: var(--orange);
                    height: 40px;
                    padding: 0 10px;
                    border: 2px solid rgba(128, 128, 128, 0.053);
                    border-radius: 10px;
                    margin-top: 10px;
                }

                span.button {
                    margin: 10px 0 -5px 0;
                    font-size: 15px;
                    color: gray;
                    width: fit-content;
                    cursor: pointer;
                }
            }

            .checkbox {
                display: flex;
                border: 1px solid #F1F1F1;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                width: fit-content;
                padding: 10px 20px;
                border-radius: 10px;

                label {
                    font-family: "Roboto Condensed";
                    margin-left: 10px;
                    font-size: 16px;

                    small {
                        color: gray;
                        font-size: 12px;
                    }
                }

                input {
                    margin: 0;
                }
            }

            .selection-container {
                position: relative;
                display: flex;
                flex-direction: column;

                button {
                    font-family: "Poppins";
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    font-size: 16px;
                    padding: 8px 45px 8px 20px;
                    border: 1px solid #F1F1F1;
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 20px;
                    margin-top: 10px;

                    svg {
                        margin-right: 10px;
                        font-size: 20px;
                        color: var(--dark-orange);
                    }

                    .down-arrow {
                        position: absolute;
                        color: #c6c6c6;
                        right: 5px;
                        width: 20px;
                    }
                }

                >div {
                    position: relative;
                }
            }

            .length {
                margin-bottom: 25px;

                > div {
                    display: flex;
                    margin-top: 10px;

                    > button {
                        font-family: "Roboto Condensed";
                        font-size: 17px;
                        padding: 7px 16px;
                        color: rgb(56, 56, 56);
                        border: 1px solid #F1F1F1;
                        outline: none;

                        &:first-child { border-radius: 10px 0 0 10px; }
                        &:last-child { border-radius: 0 10px 10px 0; }

                        &.active {
                            background-color: var(--orange);
                            color: white;
                        }
                    }
                }

                @media only screen and (max-width: 400px) {
                    > div {
                        flex-direction: column;

                        > button {
                            &:first-child { border-radius: 10px 10px 0 0; }
                            &:last-child { border-radius: 0 0 10px 10px; }
                        }
                    }
                }
            }

            .length-2 {
                margin-bottom: 25px;

                input {
                    min-width: 200px;
                    width: 200px;
                    font-size: 16px;
                }
            }

            .image-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                img {
                    width: 100%;
                    border-radius: 10px;
                }

                a {
                    font-family: "Roboto Condensed";
                    font-size: 17px;
                    text-decoration: underline;
                    color: red;
                    margin-top: 10px;
                }
            }
        }

        &.loading {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;

            > img {
                height: 30%;
                margin-top: -50px;
            }

            > p {
                font-family: "Roboto Condensed";
                font-size: 22px;
                margin: 10px 10px 0 10px;
                text-align: center;

                &.time {
                    color: var(--dark-orange);
                    font-size: 30px;
                }

                b {
                    font-weight: 600;
                }
            }

            small {
                font-family: "Poppins";
                margin-top: 20px;
                text-align: center;
                
                b {
                    font-weight: 500;
                }
            }
        }

        &.output {
            position: relative;
            z-index: 10;
            width: 60%;

            .editor {
                border-radius: 0 10px 0 0;

                .editor-content {
                    height: calc(100% - 50px);
                }
            }
        }

        &.buttons {
            width: 100%;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            border-top: 1px solid #F1F1F1;

            > div {
                display: flex;
                align-items: center;
            }

            >div:last-child {
                >button {
                    margin-left: 10px;
                }

                .data {
                    font-family: "Roboto Condensed";
                    font-size: 17px;
                    margin-right: 20px;
                }
            }

            button {
                font-family: "Roboto Condensed";
                color: #FFF;
                font-weight: 500;
                padding: 6px 20px;
                border-radius: 10px;
                min-width: 160px;
                font-size: 18px;
                background: var(--orange);

                &:hover {
                    background: var(--dark-orange);
                }

                &:disabled {
                    background-color: rgba(255, 85, 0, 0.476);
                    cursor: default;
                }

                &.blue {
                    background: rgb(2, 146, 194);

                    &:hover {
                        background: rgb(1, 118, 158);
                    }

                    &:disabled {
                        background-color: rgba(3, 112, 149, 0.326);
                        cursor: default;
                    }
                }

                svg {
                    display: none;
                }
            }

            .loader {
                height: 30px;
                margin-right: 10px;
            }
        }
    }

    @media only screen and (max-width: 1300px) {
        > div {
            &.input, &.loading {
                width: 50%;
            }
    
            &.output {
                width: 50%;
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        height: 100%;
        max-height: 100%;
    }

    @media only screen and (max-width: 875px) {
        overflow-y: auto;

        > div {
            &.input, &.loading {
                width: 100%;
                height: fit-content;
                overflow: hidden;
                padding: 10px 10px 40px 10px;
            }

            &.loading {
                padding: 0;
                height: 70%;
            }
    
            &.output {
                width: 100%;
                height: fit-content;
                min-height: 300px;
            }
    
            &.buttons {
                padding: 10px;
                height: fit-content;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        > div {
            &.buttons {
                button {
                    min-width: fit-content;
                    padding: 6px;

                    span {
                        display: none;
                    }

                    svg {
                        display: block;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        max-height: calc(100dvh - 135px);
        padding-top: 10px;
    }
}