#how-to-use {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    margin-top: 100px;

    h2 {
        font-family: "Staatliches";
        font-size: 60px;
        font-weight: 500;
        text-align: center;
        padding: 0 20px;
    }

    > img {
        position: absolute;
        right: -30%;
        top: 50px;
        opacity: 0.8;
        z-index: -1;
    }

    > h2 {
        margin-bottom: 30px;
    }

    > div:not(.last) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: 20px;
        border-radius: 20px;

        > .content {
            background-color: rgb(249, 249, 249);
            border-radius: 12px;
            padding: 20px 30px;
            box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.168);
            transition: 200ms;

            &:hover {
                box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.226);
            }
        }
    }

    > div {
        > .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            width: 30%;
            height: 300px;

            h3 {
                font-size: 30px;
                font-family: "Roboto Condensed";
                margin-bottom: 10px;

                .brand { font-size: 30px; }
            }

            p {
                font-family: "Poppins";
                font-size: 16px;
                text-align: center;

                .brand { font-size: 16px; }
            }

            .num {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                min-width: 30px;
                background-color: var(--orange);
                color: white;
                margin-bottom: 10px;
            }
        }
    }

    .last {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;

        > .content {
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;

            p, h3 { text-align: center; }
            h3 { font-size: 35px; }
            p { font-size: 20px; }
        }
    }

    @media only screen and (max-width: 1500px) {
        width: 90%;
    }

    @media only screen and (max-width: 1220px) {
        > div > .content {
            height: fit-content;
        }
    }

    @media only screen and (max-width: 768px) {
        margin-top: 50px;
        width: 100%;

        > h2 {
            margin-bottom: 0;
        }

        > div {
            flex-direction: column;
            padding-bottom: 0 !important;

            &.last {
                padding: 0 20px;
            }

            > .content {
                width: 100%;
                margin-bottom: 20px;
                border-radius: 12px;
                padding: 40px 30px;
                box-shadow: none !important;
                background-color: transparent !important;

                &:hover {
                    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.266);
                }

                h3 {
                    font-family: "Roboto Condensed";
                    margin-bottom: 10px;
    
                    .brand { font-size: 30px; }
                }
    
                p {
                    font-family: "Poppins" !important;
                    font-size: 17px !important;
                    text-align: center !important;
    
                    .brand { font-size: 16px; }
                }
            }
        }
    }

    @media only screen and (max-width: 400px) {
        h2 {
            font-size: 40px;
            margin-bottom: 0;
        }
    }
}