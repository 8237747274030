#payment-failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0 0 0;

    svg {
        font-size: 400px;
        height: auto;
        color: red;
    }

    h1 {
        font-family: "Staatliches";
        font-size: 100px;
        font-weight: 500;
        line-height: 90px;
        margin: 60px 10px 10px 10px;
        text-align: center;
    }

    p {
        font-family: "Poppins";
        font-size: 20px;
        margin: 10px 20px;
        text-align: center;
    }

    @media only screen and (max-width: 768px) {
        h1 {
            font-size: 14vw;
        }
    }

    @media only screen and (max-width: 500px) {
        svg {
            width: 80%;
        }

        h1 {
            margin-top: 20px;
        }
    }
}