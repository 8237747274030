.editor {
    position: relative;
    z-index: 5;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &-wrapper {
        min-width: 100%;
        max-width: 100%;
        height: 100%;

        .rdw-editor-toolbar {
            padding-right: 30px;

            .rdw-link-modal,
            .rdw-embedded-modal,
            .rdw-emoji-modal,
            .rdw-image-modal,
            .rdw-colorpicker-modal {
                left: -205px;

                @media only screen and (max-width: 500px) {
                    position: fixed;
                    right: 35px;
                    left: auto;
                    margin: auto;
                    bottom: 70px;
                    top: auto;
                }
            }

            .rdw-colorpicker-modal {
                left: -145px;
                height: fit-content;
    
                .rdw-colorpicker-modal-options {
                    overflow-x: hidden;
                }
            }

            .rdw-list-wrapper,
            .rdw-text-align-wrapper {
                display: none;
            }

            .rdw-inline-wrapper {
                .rdw-option-wrapper {
                    &:nth-child(6),
                    &:nth-child(7) {
                        display: none;
                    }
                }
            }

            @media only screen and (max-width: 500px) {
                .rdw-fontfamily-wrapper,
                .rdw-block-wrapper {
                    display: none;
                }
            }
        }
    }

    &-content {
        border-right: 1px solid #F1F1F1;
        margin-top: -5px;
        height: calc(100% - 45px);
        max-width: 100%;
        padding: 10px;
        overflow-y: auto;

        .DraftEditor-root {
            height: calc(100% - 20px);

            .public-DraftEditor-content > div {
                height: 100%;

                .public-DraftStyleDefault-block {
                    // word-break: break-all;
                    max-width: 100%;
                }

                // Images
                figure {
                    margin: 0 0 10px 0;
                }

                .rdw-image {
                    &-alignment {
                        img {
                            width: 40vw;
                            display: flex;
                            margin: 0 auto;
                            border-radius: 16px;

                            @media only screen and (max-width: 1100px) {
                                width: 70vw;
                            }

                            @media only screen and (max-width: 768px) {
                                width: 100%;
                            }
                        }
                    }

                    &-alignment-options-popup {
                        display: none;
                    }
                }
            }
        }

        &::-webkit-scrollbar-track { background: #fff; }
        &::-webkit-scrollbar-thumb { background: #e2e2e2; }
        &::-webkit-scrollbar-thumb:hover { background: #bebebe; }

        @media only screen and (max-width: 1000px) {
            height: calc(100% - 105px);

            .DraftEditor-root {
                height: calc(100% - 85px);
            }
        }

        @media only screen and (max-width: 500px) {
            height: calc(100% - 135px);

            .DraftEditor-root {
                height: calc(100% - 115px);
            }
        }
    }

    .save-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 23px;
        margin-bottom: 4px;
        padding: 0;
        width: 30px;
        
        &:not(:disabled) {
            color: var(--orange);
            cursor: pointer;

            &:hover {
                color: var(--dark-orange);
            }
        }
        &:disabled {
            color: gray;
            cursor: default;
        }
    }
}