#faq {
    margin-top: 100px;

    h2 {
        font-family: "Staatliches";
        font-size: 60px;
        font-weight: 500;
        text-align: center;
        padding: 0 20px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -5px;

        h2 {
            margin-bottom: 100px;
        }

        .questions {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding-bottom: 50px;

            > .container {
                &:last-child .answer { margin-bottom: 0; }

                .question {
                    margin-bottom: 10px;
    
                    h4 {
                        font-family: "Roboto Condensed";
                        font-size: 32px;
                    }
                }
    
                .answer {
                    margin-bottom: 50px;
                    
                    p {
                        font-family: "Poppins";
                        font-size: 20px;
    
                        .brand { font-size: 20px; }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        > .content {
            .questions {
                width: 90%;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        margin-top: 50px;
        
        > .content {
            .questions > .container {
                .question > h4 {
                    text-align: center;
                    font-size: 27px;
                }

                .answer > p {
                    text-align: center;
                    font-size: 17px;
                }
            }

            h2 {
                margin-bottom: 60px;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        h2 {
            font-size: 40px;
        }
    }
}