#payment-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0 0 0;

    img {
        width: 500px;
        color: red;
    }

    h1 {
        font-family: "Staatliches";
        font-size: 100px;
        font-weight: 500;
        margin: 30px 10px 20px 10px;
        text-align: center;
    }

    p {
        font-family: "Poppins";
        font-size: 20px;
        margin: 10px 20px;
        text-align: center;
    }

    @media only screen and (max-width: 768px) {
        padding: 50px 0 0 0;

        h1 {
            font-size: 14vw;
        }

        img {
            width: 90%;
        }
    }
}