@import '../../../assets/scss/mixins.scss';

#bulk {
    height: calc(100dvh - 130px);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    margin: 25px;

    .loading {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        > img {
            height: 30%;
            margin-top: -100px;
            @include nodrag;
        }

        > p {
            font-family: "Roboto Condensed";
            font-size: 22px;
            margin: 10px 10px 0 10px;
            text-align: center;

            &.time {
                color: var(--dark-orange);
                font-size: 30px;
            }

            b {
                font-weight: 600;
            }
        }

        small {
            font-family: "Poppins";
            margin-top: 20px;
            text-align: center;

            b {
                font-weight: 500;
            }
        }

        button.stop {
            font-family: "Poppins";
            font-size: 17px;
            background-color: red;
            color: white;
            padding: 4px 10px;
            display: flex;
            align-items: center;
            border-radius: 7px;
            margin-top: 30px;

            > svg {
                margin-right: 10px;
            }
        }
    }

    h3 {
        font-family: "Staatliches";
        font-weight: 400;
        font-size: 25px;
        display: flex;
        align-items: center;

        small {
            margin-left: 10px;
        }
    }

    small {
        font-family: "Poppins";
        color: gray;
        font-size: 15px;
        margin-bottom: 20px;
        font-weight: 300;

        > span,
        > a {
            font-family: "Poppins";
            font-size: 14px;
            color: var(--dark-orange);
            font-weight: 500;
        }

        &.content-words {
            text-align: end;
            font-size: 13px;
            margin-top: 5px;
        }
    }

    .templates {
        width: 100%;
        display: grid;
        margin-bottom: 40px;
        margin-top: 10px;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;

        .selected {
            > div {
                background-color: #fc86003b;
            }
        }

        > div > div {
            height: 100%;
            box-shadow: 0 0 10px 0 #1c1c1c20;
        }

        @media only screen and (max-width: 1300px) {
            grid-template-columns: 1fr;
        }
    }

    .keyword-selection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        margin-top: 10px;

        > .single-selection {
            position: relative;
            width: 100%;
            padding: 15px 23px;
            border: 2px solid #e4e4e4;
            transition: 200ms;
            cursor: pointer;

            &:first-child {
                border-radius: 10px 0 0 10px;
                border-right: 2px solid transparent;
            }

            &:last-child {
                border-radius: 0 10px 10px 0;
                border-left: 2px solid transparent;
            }

            &:hover:not(.selected) {
                background-color: rgb(239, 239, 239, 0.3);
            }

            &.selected {
                cursor: default;
                border-color: var(--orange);
                color: var(--dark-orange);

                &:hover {
                    border-color: var(--dark-orange);
                    color: rgb(255, 81, 0);
                }
            }

            &.only {
                border-radius: 10px;
                border: 2px solid #e4e4e4;

                &.selected {
                    border-color: var(--orange);

                    &:hover {
                        border-color: var(--dark-orange);
                    }
                }
            }

            h4 {
                font-family: "Roboto Condensed";
                font-size: 28px;
                margin-bottom: 10px;
            }

            p {
                font-family: "Roboto Condensed";
                font-size: 19px;
            }

            .check {
                position: absolute;
                margin-top: 5px;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 28px;

                @media only screen and (max-width: 590px) {
                    display: none;
                }
            }
        }

        @media only screen and (max-width: 1000px) {
            flex-direction: column;

            > .single-selection {
                &:first-child {
                    border-radius: 10px 10px 0 0;
                    border: 2px solid #e4e4e4;
                    border-bottom: 2px solid transparent;
                }
                &:last-child {
                    border-radius: 0 0 10px 10px;
                    border: 2px solid #e4e4e4;
                    border-top: 2px solid transparent;
                }
                &.selected {
                    border: 2px solid var(--dark-orange);
                }
            }
        }
    }

    textarea {
        resize: vertical;
        outline-color: var(--orange);
        min-height: 350px;
        padding: 10px;
        border: 2px solid #e4e4e4;
        border-radius: 10px;
        margin-top: 0px;
        margin-bottom: 40px;
        font-size: 18px;
    }

    .length {
        margin-bottom: 40px;

        > div {
            display: flex;
            margin-top: 20px;

            > button {
                font-family: "Roboto Condensed";
                font-size: 17px;
                padding: 7px 16px;
                color: rgb(56, 56, 56);
                border: 1px solid #f1f1f1;
                outline: none;

                &:first-child {
                    border-radius: 10px 0 0 10px;
                }
                &:last-child {
                    border-radius: 0 10px 10px 0;
                }

                &.active {
                    background-color: var(--orange);
                    color: white;
                }
            }
        }

        @media only screen and (max-width: 400px) {
            > div {
                flex-direction: column;

                > button {
                    &:first-child {
                        border-radius: 10px 10px 0 0;
                    }
                    &:last-child {
                        border-radius: 0 0 10px 10px;
                    }
                }
            }
        }
    }

    .selection-container {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        &.website {
            margin-top: 20px;

            button {
                overflow-wrap: anywhere;
            }
        }

        button {
            font-family: "Poppins";
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            font-size: 16px;
            padding: 8px 45px 8px 20px;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            white-space: pre-wrap;

            svg {
                margin-right: 10px;
                font-size: 20px;
                color: var(--dark-orange);
            }

            .down-arrow {
                position: absolute;
                color: #c6c6c6;
                right: 5px;
                width: 20px;
            }
        }

        .remove {
            color: var(--orange);
            text-decoration: underline;
            margin-left: 10px;
            font-size: 30px;
            height: 30px;

            &:hover {
                color: var(--dark-orange);
            }
        }

        > div:not(.inner-box) {
            position: relative;
        }

        .inner-box {
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .image-container {
        .checkbox {
            display: flex;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            width: fit-content;
            padding: 10px 20px;
            border-radius: 10px;
            margin-top: 10px;

            label {
                font-family: "Roboto Condensed";
                margin-left: 10px;
                font-size: 16px;

                small {
                    color: gray;
                    font-size: 12px;
                }
            }

            input {
                margin: 0;
            }
        }
    }

    .buttons {
        width: 100%;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 30px;

        > div {
            display: flex;
            align-items: center;
        }

        > div:last-child {
            > button {
                margin-left: 10px;
            }

            .data {
                font-family: "Roboto Condensed";
                font-size: 17px;
                margin-right: 20px;
            }
        }

        button {
            font-family: "Roboto Condensed";
            color: #fff;
            font-weight: 500;
            padding: 6px 20px;
            border-radius: 10px;
            min-width: 200px;
            font-size: 18px;
            background: var(--orange);

            &:hover {
                background: var(--dark-orange);
            }

            &:disabled {
                background-color: rgba(255, 85, 0, 0.476);
                cursor: default;
            }

            &.blue {
                background: rgb(2, 146, 194);

                &:hover {
                    background: rgb(1, 118, 158);
                }

                &:disabled {
                    background-color: rgba(3, 112, 149, 0.326);
                    cursor: default;
                }
            }

            svg {
                display: none;
            }
        }

        .loader {
            height: 30px;
            margin-right: 10px;
        }

        @media only screen and (max-width: 500px) {
            button {
                max-width: 100%;
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        height: calc(100dvh - 120px);

        > div { height: 100%; }
    }

    @media only screen and (max-width: 768px) {
        height: calc(100dvh - 100px);
    }

    @media only screen and (max-width: 600px) {
        margin: 15px;
        height: calc(100dvh - 130px);
    }
}
