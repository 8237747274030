:root {
    --toastify-icon-color-error: var(--dark-orange);
    --toastify-toast-width: 350px
}

// React-toastify
.Toastify__toast {
    background-color: #1c1c1c !important;
    color: #e3e3e3 !important;

    .Toastify__progress-bar--error {
        background: var(--dark-orange);
    }

    .Toastify__close-button {
        color: #fff;
        opacity: 0.8;
    }
}