#download-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.45);

    .content {
        background: white;
        box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 40%;

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: fit-content;
            padding: 15px;

            h4 {
                font-family: "Roboto Condensed";
                font-weight: 600;
                font-size: 23px;
            }

            svg {
                height: 15px;
                cursor: pointer;
            }
        }

        .body {
            padding: 25px;

            p {
                font-family: "Poppins";
                font-size: 20px;
            }

            .buttons {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                button {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    outline: none;
                    font-family: "Roboto Condensed";
                    color: #FFF;
                    font-weight: 500;
                    padding: 6px 5px;
                    border-radius: 3px;
                    min-width: 160px;
                    width: 50%;
                    font-size: 18px;
                    margin-top: 10px;
                    background: var(--orange);
        
                    &:hover {
                        background: var(--dark-orange);
                    }

                    b {
                        margin: 0 5px;
                    }

                    svg {
                        margin-right: 5px;
                    }
                }
            }

            .btn-selection {
                display: flex;
                margin-bottom: 20px;
                margin-top: 5px;

                > button {
                    font-family: "Roboto Condensed";
                    font-size: 17px;
                    padding: 7px 16px;
                    color: rgb(56, 56, 56);
                    border: 1px solid #F1F1F1;
                    outline: none;

                    &:first-child { border-radius: 10px 0 0 10px; }
                    &:last-child { border-radius: 0 10px 10px 0; }

                    &.active {
                        background-color: var(--orange);
                        color: white;
                    }
                }
            }
        }

        @media only screen and (max-width: 1410px) {
            width: 60%;

            .body > .buttons > button { width: 100%; }
        }

        @media only screen and (max-width: 768px) {
            width: 90%;
        }

        @media only screen and (max-width: 500px) {
            .body {
                padding: 15px;

                p {
                    font-size: 17px;
                    text-align: center;
                }
            }
        }

        @media only screen and (max-width: 350px) {
            .body > .buttons > button {
                font-size: 14px;
            }
        }
    }
}