#dashboard {
    position: relative;
    width: calc(100vw - 300px);
    left: 300px;
    height: 100%;

    > .content {
        padding: 0;

        > div:not(#overview) {
            padding: 25px;
        }
    }

    @media only screen and (max-width: 1100px) {
        width: calc(100vw - 70px);
        left: 70px;
    }

    @media only screen and (max-width: 768px) {
        width: calc(100vw - 50px);
        left: 50px;
    }

    @media only screen and (max-width: 500px) {
        > .content {
            > div:not(#overview) {
                padding: 10px;
            }
        }
    }

    @media
        only screen and (max-height: 600px) and (max-width: 1100px),
        only screen and (max-width: 600px)
    {
        width: 100vw;
        left: 0;
        padding-bottom: 0px;

        > .content {
            height: calc(100dvh - 50px - 50px);

            > div:not(#overview) {
                padding: 15px;
            }
        }
    }
}