#dashboard-nav {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.08);
    background-color: white;

    > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        > .brand-container {
            display: flex;
            justify-content: flex-start;
            width: 100%;

            a {
                text-align: start;
                display: flex;
                align-items: center;
                justify-content: center;
                width: fit-content;
                margin-bottom: 10px;
                
                .brand {
                    width: 100%;
                    padding: 10px 20px 0 20px;
                }

                .logo {
                    display: none;
                    width: 100%;
                }
            }
        }

        &.items > a {
            &.bottom-link { display: none; }
        }

        > a {
            font-family: "Poppins";
            font-weight: 500;
            width: 100%;
            padding: 19px;
            font-size: 18px;
            display: flex;
            align-items: center;
            border-right: 5px solid transparent;
            color: rgb(93, 93, 93);

            &:hover {
                color: black;
                background-color: rgba(0, 0, 0, 0.045);
            }

            &.active {
                background-color: rgba(238, 238, 238, 0.5);
                border-right: 5px solid var(--orange);
                color: #000;

                &:hover {
                    background-color: rgba(197, 197, 197, 0.5);
                    border-color: var(--dark-orange);
                }
            }

            &.notification {
                color: #00ab60;

                &.active {
                    border-right: 5px solid #00ab60;

                    &:hover {
                        border-color: #008a4e;
                    }
                }
            }

            > svg {
                margin-right: 10px;
            }

            @media only screen and (max-height: 660px) and (min-width: 1100px) {
                padding: 10px 19px;
            }
            @media only screen and (max-height: 500px) and (min-width: 1100px) {
                padding: 5px 19px;
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        width: 70px;

        > div {
            > .brand-container {
                padding: 10px;

                a {
                    margin-bottom: 0;

                    .brand { display: none; }
                    .logo { display: block; }
                }
            }

            > a {
                padding: 0;
                height: 70px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                span { display: none; }
                > svg {
                    font-size: 24px;
                    margin-right: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        width: 50px;

        > div {
            > .brand-container {
                padding: 5px;
            }

            > a {
                height: 50px;

                > svg {
                    height: 20px;
                    min-width: 20px;
                    font-size: 20px;
                    margin-right: 0;
                }
            }
        }
    }

    @media
        only screen and (max-height: 600px) and (max-width: 1100px),
        only screen and (min-height: 600px) and (max-width: 600px)
    {
        top: auto;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        flex-direction: row;
        align-items: center;

        > div {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            > .brand-container {
                display: none;
            }

            > a {
                height: 50px;
                width: 50px;
                border-right: none;

                &.active {
                    border-top: 5px solid var(--orange);
                    border-right: none;
                }

                &.notification {
                    border-top: 5px solid #00ab60;
                    border-right: none;
                }

                &.bottom-link {
                    display: flex !important;
                }

                > svg {
                    height: 17px;
                }
            }

            &.bottom {
                display: none;
            }
        }
    }
}