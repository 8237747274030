#home {
    > div {
        h2 {
            font-family: "Staatliches";
            font-size: 60px;
            font-weight: 500;
            text-align: center;
            padding: 0 20px;
        }

        @media only screen and (max-width: 500px) {
            h2 { font-size: 40px; }
            h3 { font-size: 27px; }
        }
    }

    .dashboard-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 70px 0 0 0;
        background: var(--bg);

        > .image-container {
            position: relative;
            width: 80%;
            border-radius: 12px;

            > .dashboard-img {
                width: 100%;
                border-radius: 12px;
                filter: drop-shadow(0 0 4px #00000034);
            }
        }

        &.multiple {
            padding: 0;
            margin-top: 175px;

            > .image-container {
                margin-bottom: 10px;
                
                > .dashboard-img {
                    width: 80%;
                    
                    &:not(:first-child) { margin-top: -200px; }
                    &:nth-child(odd) { float: right; }
                }
    
                @media only screen and (max-width: 1280px) {
                    > .dashboard-img {
                        width: 60%;
                        
                        &:not(:first-child) { margin-top: -100px; }
                    }
                }
    
                @media only screen and (max-width: 900px) {
                    display: none;
                }
            }
        }

        @media only screen and (max-width: 1450px) {
            > .image-container { width: 90%; }
        }

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    #features {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 120px;

        > div {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: calc(80% + 60px);

            > .feature {
                position: relative;
                padding: 50px 30px;
                width: calc(33% - 40px);
                margin: 0 20px 40px 20px;
                box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.168);
                border-radius: 12px;
                background-color: var(--bg);
                transition: 200ms;
                cursor: pointer;
    
                &::before {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 0;
                    background-color: var(--orange);
                    border-radius: 12px;
                    transition: 200ms;
                }
    
                &:hover {
                    &, p, .brand, .brand > .orange-text { color: white; }
    
                    &::before {
                        height: 100%;
                    }
                }
    
                > * {
                    position: relative;
                    z-index: 2;
                }
                
                > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
    
                    &:first-child {
                        padding-bottom: 20px;
                    }
                }
    
                h3 {
                    font-family: "Staatliches";
                    text-align: center;
                    font-size: 34px;
                    font-weight: 500;
                }
    
                p {
                    font-family: "Poppins";
                    margin-top: 30px;
                    font-size: 18px;
                    text-align: center;
                    width: 80%;
                    color: #363534;
    
                    > .brand {
                        font-size: 18px;
                    }
                }
            }
        }

        @media only screen and (max-width: 1450px) {
            > div { width: 95%; }
        }

        @media only screen and (max-width: 1150px) {
            > div > .feature {
                padding: 40px 10px;

                h3 {
                    font-size: 26px;
                }

                p {
                    font-size: 15px;
                }
            }
        }

        @media only screen and (max-width: 920px) {
            margin-top: 40px;

            > div > .feature {
                width: calc(50% - 20px);
                margin: 0 10px 20px 10px;

                svg {
                    height: 40px;
                }
            }
        }

        @media only screen and (max-width: 550px) {
            > div > .feature {
                width: 100%;
                margin: 10px 0;
            }
        }
    }

    #our-service {
        margin: 70px 0;
        flex-direction: column;
        display: flex;
        align-items: center;

        p {
            font-family: "Poppins";
            font-size: 20px;
            padding: 20px;
            width: 60%;
            text-align: center;

            b {
                font-weight: 500;
            }

            .brand {
                font-size: 20px;
            }
        }

        @media only screen and (max-width: 768px) {
            p {
                width: 100%;
            }
        }
    }

    #demo {
        display: flex;
        width: 80%;
        margin: 0 auto;
        box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.168);
        border-radius: 12px;

        .prompts {
            display: flex;
            flex-direction: column;
            width: 30%;

            .prompt {
                display: flex;
                align-items: center;
                padding: 20px;
                width: 100%;
                cursor: pointer;

                &.active {
                    border-left: 5px solid var(--orange);
                    width: calc(100% - 5px);
                    padding-left: 15px;
                }

                p {
                    font-weight: 600;
                    font-size: 18px;
                }

                span {
                    color: gray;
                }

                .icon {
                    border-radius: 12px;
                    background-color: #fc86003b;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 60px;
                    width: 60px;
                    min-width: 60px;
                    margin-right: 10px;
        
                    svg path {
                        stroke: var(--dark-orange);
                    }

                    svg {
                        font-size: 30px;
                    }
                }

                > div {
                    display: flex;
                    flex-direction: column;
                }
            }

            @media only screen and (max-width: 1280px) {
                width: 40%;
            }
        }

        .result {
            width: 70%;
            border-left: 2px solid rgba(0, 0, 0, 0.096);
            padding: 8px;
            padding-bottom: 0;

            .content {
                font-family: "Roboto";
                overflow-y: auto;
                height: 600px;
                padding: 10px;
                padding-bottom: 0;

                h2 {
                    padding: 0;
                }
            }

            @media only screen and (max-width: 1280px) {
                width: 60%;
            }
        }

        @media only screen and (max-width: 1000px) {
            display: none;
        }
    }

    #contact {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 80px 0;
        margin-top: 70px;
        background-color: #1c1c1c;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            color: white;
            width: 70%;
            height: 400px;
            padding: 0 40px;
            border-radius: 20px;
            background: rgba(38, 38, 38, 0.834);

            &::before {
                content: '';
                position: absolute;
                top: -5px;
                left: -5px;
                z-index: -1;
                width: calc(100% + 10px);
                height: 410px;
                border-radius: 25px;
                background-image: linear-gradient(60deg, #595959 0%, #2d2d2d 100%);
            }

            h2 {
                margin-bottom: 20px;
                text-shadow: 3px 1px 5px rgba(0,0,0,0.21);
            }
            
            p {
                width: 70%;
                text-align: center;
                margin: 0 auto;
                font-size: 20px;
                font-family: "Poppins";
                text-shadow: 3px 1px 5px rgba(0, 0, 0, 0.342);

                a {
                    color: var(--orange);

                    &:hover { color: var(--dark-orange); }
                }

                span {
                    font-weight: 500;
                }
            }
        }

        @media only screen and (max-width: 1000px) {
            > div {
                padding: 30px;
                height: fit-content;

                &::before {
                    height: calc(100% + 10px);
                }
            }
        }

        @media only screen and (max-width: 768px) {
            margin-top: 50px;
            padding: 25px 0;

            > div {
                width: 90%;
                padding: 20px;
                background-color: transparent;

                &::before { display: none; }
                p { width: 100%; }
            }
        }

        @media only screen and (max-width: 400px) {
            h2 { font-size: 40px; }

            div p {
                width: 90%;
                font-size: 18px;
            }
        }

        @media only screen and (max-width: 300px) {
            div p { font-size: 16px; }
        }
    }

    #signup-now {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 30px 100px;
        margin: 100px auto 0 auto;
        border-radius: 20px;
        background: linear-gradient(to bottom, var(--orange), var(--dark-orange));
        color: white;
        font-family: "Poppins";

        p {
            margin-bottom: 20px;
            font-size: 20px;
            color: #fefefe;
            text-shadow: 3px 1px 5px rgba(0,0,0,0.21);
            text-align: center;
        }

        a {
            font-family: "Roboto Condensed";
            font-weight: 600;
            font-size: 50px;
            color: rgb(255, 255, 255);
            text-shadow: 3px 1px 5px rgba(0,0,0,0.21);
            text-align: center;
        }

        @media only screen and (max-width: 920px) {
            border-radius: 0;
            margin-top: 150px;
            margin-bottom: 0;
        }

        @media only screen and (max-width: 460px) {
            padding: 30px 15px;
        }
    }

    // Containers
    .howtouse-container {
        padding: 40px 0 40px 0;
    }

    .pricing-container {
        margin: 140px 0 100px 0;
    }

    .faq-container {
        margin-top: -250px;
        margin-bottom: -350px;

        > #faq {
            margin-top: 0;

            .content {
                height: fit-content;
                background: linear-gradient(to bottom, #f3f4f5, #f8f8f8 50%, #e7e7e7);
            }
        }

        .wave-2 {
            margin-top: -5px;
        }

        @media only screen and (max-width: 1500px) {
            margin-top: -100px;
        }

        @media only screen and (max-width: 768px) {
            margin-bottom: -200px;
            margin-top: 0;
        }
    }
}