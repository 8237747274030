@import 'fonts.scss';
@import 'mixins.scss';
@import 'overwrites.scss';

:root {
    --orange: #FC8800;
    --dark-orange: #fc6d00;
    --bg: #FBFBFB;
}

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: #000;
    background: var(--bg);
    font-family: "Roboto";
}

a, button, input, textarea {
    transition: 200ms;
    border: none;
    background-color: transparent;
    text-decoration: none;
    color: #000;
    font-family: "Roboto";
}

textarea {
    transition: 0ms;
}

a, button {
    cursor: pointer;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

main {
    max-width: 2440px;
    display: block;
    overflow: hidden;
    margin: 0 auto 100px auto;

    &.is-dashboard {
        margin: 0 auto;
    }

    @media only screen and (max-width: 925px) {
        &:not(.is-dashboard) { margin-top: 70px; }
    }

    @media only screen and (max-width: 500px) {
        &:not(.is-dashboard) {
            margin-top: 70px;
            margin-bottom: 20px;
        }
    }
}

.main-loader {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.568);
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    @include nodrag;
}

::selection {
    background: var(--dark-orange);
    color: white;
}

@include scrollbar;