#settings {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 130px);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    margin: 25px;
    overflow-y: auto;

    > h1 {
        font-family: "Staatliches";
        font-weight: 400;

        &:not(:first-child) { margin-top: 20px; }
    }

    > h2 {
        font-family: "Roboto Condensed";
        font-weight: 400;
        font-size: 20px;
        margin-top: 10px;
        padding: 0 10px;

        small {
            font-size: 12px;
            font-weight: 400;
            color: gray;
        }
    }

    > p {
        font-family: "Poppins";
        color: gray;
        font-size: 14px;
        font-weight: 300;
        margin-top: 5px;
        padding: 0 20px;

        a {
            color: var(--orange);
            font-weight: 500;
        }
    }

    section {
        position: relative;
        width: 40%;
        margin: 0 0 10px 0;
        padding: 0 20px;

        input, textarea {
            outline-color: var(--orange);
            width: 100%;
            height: 40px;
            padding: 0 70px 0 10px;
            border: 2px solid rgba(128, 128, 128, 0.053);
            border-radius: 10px;
            margin-top: 10px;
            resize: none;
        }

        textarea {
            height: 200px;
            padding: 10px;
        }

        button {
            &.save, &.delete {
                position: absolute;
                right: 20px;
                bottom: 5px;
                color: gray;
                
                &:hover { color: var(--dark-orange); }
    
                svg {
                    font-size: 30px;
                }

                &.save-name {
                    bottom: 1px;
                }

                &.delete {
                    right: 50px;

                    &:hover { color: red; }
                }
            }

            &.send {
                font-family: "Roboto Condensed";
                color: #FFF;
                font-weight: 500;
                padding: 6px 20px;
                border-radius: 10px;
                min-width: 160px;
                margin-top: 20px;
                font-size: 18px;
                background: var(--orange);

                &:hover {
                    background: var(--dark-orange);
                }
            }
        }

        > p {
            font-family: "Roboto";
            margin-bottom: -10px;
            margin-top: 10px;
            color: rgb(50, 50, 50);
            font-size: 15px;
        }

        .key-count {
            position: absolute;
            right: 35px;
            top: 20px;
            font-weight: 500;
            color: #1c1c1c;
        }

        &.templates {
            margin-top: 20px;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 20px;
            column-gap: 20px;

            .template {
                box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.06);
                height: 100%;
            }

            > div {
                position: relative;
                transition: 200ms;

                &.disabled {
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(128, 128, 128, 0.391);
                        border-radius: 7px;
                        transition: 200ms;
                        cursor: pointer;
                    }

                    &:hover::after {
                        background-color: rgba(71, 71, 71, 0.391);
                    }

                    .template .content > .icon {
                        background-color: rgb(192, 192, 192);

                        > svg, > svg > path {
                            color: gray;
                            stroke: gray;
                        }
                    }
                }
            }

            @media only screen and (max-width: 900px) {
                grid-template-columns: 1fr;
            }
        }

        @media only screen and (max-width: 900px) {
            width: 100%;
        }
    }

    @media only screen and (max-width: 1100px) {
        height: calc(100dvh - 120px);
    }

    @media only screen and (max-width: 768px) {
        height: calc(100dvh - 100px);
    }

    @media only screen and (max-width: 600px) {
        margin: 15px;
        height: calc(100dvh - 130px);
    }

    @media only screen and (max-width: 500px) {
        padding: 20px 10px;

        > h1, > h2, > p {
            text-align: center;
        }

        section {
            button {
                display: flex;
                margin: 0 auto;
            }
        }
    }
}