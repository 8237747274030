#credits {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100dvh - 130px);
    width: 100%;
    padding: 0 !important;

    .container {
        display: flex;
        min-height: 40%;
        width: 80%;

        .form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 10px;
            background-color: white;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            width: 65%;
            padding: 20px;

            @media only screen and (max-width: 1100px) {
                margin-top: 20px;
            }

            .inputs {
                display: flex;
                flex-direction: column;

                > div:not(.credits-fast) {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 30px;

                    label {
                        font-family: "Roboto Condensed";

                        &, .brand { font-size: 17px; }
                    }

                    > input {
                        font-family: "Poppins";
                        font-size: 18px;
                        outline-color: var(--orange);
                        height: 40px;
                        padding: 0 10px;
                        border: 2px solid rgba(29, 29, 29, 0.137);
                        border-radius: 10px;
                        margin-top: 10px;
                    }

                    &.selections {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;

                        > div {
                            width: 100%;

                            &:first-child { margin-right: 5px; }
                            &:last-child { margin-left: 5px; }

                            > .selection-container {
                                position: relative;
                                display: flex;
                                flex-direction: column;
            
                                button {
                                    font-family: "Poppins";
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    width: 100%;
                                    font-size: 16px;
                                    padding: 8px 45px 8px 20px;
                                    padding: 8px 20px;
                                    border: 1px solid #F1F1F1;
                                    border: 2px solid rgba(29, 29, 29, 0.137);
                                    border-radius: 20px;
                                    margin-top: 10px;
            
                                    svg {
                                        margin-right: 10px;
                                        font-size: 20px;
                                        color: var(--dark-orange);
                                    }
        
                                    .down-arrow {
                                        position: absolute;
                                        color: #c6c6c6;
                                        right: 5px;
                                        width: 20px;
                                    }
                                }
            
                                >div {
                                    position: relative;
                                }
                            }
                        }
    
                        .currency {
                            button span {
                                color: var(--dark-orange);
                                margin-right: 5px;
                            }
                        }
                    }
                }

                > .credits-fast {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: -20px;

                    button {
                        font-family: "Poppins";
                        font-weight: 500;
                        color: rgb(59, 59, 59);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        height: 40px;
                        border-radius: 10px;
                        width: calc(20% - 10px);
                        border: 2px solid #c7c7c760;
                        background-color: #f8f8f8;

                        &:hover {
                            background-color: #eeeeee;
                            color: black;
                        }
                    }
                }

                small {
                    font-family: "Poppins";
                    display: flex;
                    align-items: center;
                    color: gray;
                    font-size: 13px;
                    margin-top: 10px;

                    svg {
                        margin-right: 10px;
                    }
                }
            }

            .total {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-end;
                margin-top: 50px;

                .text {
                    h4 {
                        font-family: "Roboto Condensed";
                        font-size: 20px;
                    }

                    span:not(.discount) {
                        font-family: "Poppins";
                        display: block;
                        font-size: 50px;
                        color: var(--orange);
                        margin-top: 15px;
                        line-height: 40px;
                    }

                    .discount {
                        font-family: "Poppins";
                        font-size: 20px;
                        color: gray;
                        text-decoration: line-through;
                    }

                    .loader {
                        margin-top: 10px;
                        height: 34px;
                    }
                }

                a {
                    font-family: "Roboto Condensed";
                    font-size: 20px;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: var(--orange);
                    color: white;
                    height: 40px;
                    min-width: 200px;
                    padding: 0 20px;
                    text-align: center;

                    &:hover { background-color: var(--dark-orange); }

                    &.button-loading {
                        cursor: default;
                        background-color: rgba(255, 194, 154, 0.761);
                        
                        img {
                            height: 30px;
                        }
                    }
                }
            }
        }

        .description {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 35%;
            padding: 0 20px;

            h5 {
                font-family: "Roboto Condensed";
                display: flex;
                align-items: flex-end;
                font-size: 20px;
                color: var(--dark-orange);

                svg {
                    margin-right: 10px;
                }
            }
            
            p {
                margin-top: 5px;
                margin-bottom: 20px;

                a {
                    color: var(--orange);
                    font-weight: 500;

                    &:hover { color: var(--dark-orange); }
                }
            }

            @media only screen and (max-width: 768px) {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 1620px) {
        > .container {
            width: 95%;
        }
    }

    @media only screen and (max-width: 1100px) {
        height: calc(100dvh - 120px);
        align-items: flex-start;

        > .container {
            flex-direction: column;
            overflow: hidden;
            height: 100%;
            width: 100%;
            margin: 25px;

            > div {
                &.form {
                    height: 100%;
                    width: 100%;
                    margin-top: 0;

                    .total {
                        margin-top: 5px;
                    }
                }

                &.description {
                    margin-top: 40px;
                    width: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        height: calc(100dvh - 75px);

        > .container {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            height: 100%;

            > div {
                &.form {
                    height: 100%;
                    border-radius: 10px;
                    overflow-y: auto;
                    box-shadow: none;
                    margin-bottom: 20px;

                    .inputs {
                        > div:not(.credits-fast).selections {
                            flex-direction: column;
                            
                            > .currency {
                                margin-top: 20px;
                            }
                        }

                        .credits-fast {
                            flex-wrap: wrap;
                            justify-content: center;

                            button {
                                margin: 3px;
                                font-size: 14px;
                                height: fit-content;
                                width: calc(50% - 6px);
                            }
                        }

                        small {
                            svg {
                                display: none;
                            }
                        }
                    }

                    .total {
                        flex-wrap: wrap;
                        padding-top: 10px;

                        .text {
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;

                            span:not(.discount) {
                                font-size: 23px !important;
                                margin: 0 0 0 5px !important;
                                font-weight: 600;
                            }

                            .price-with-discount {
                                height: fit-content;
                                display: flex;
                                align-items: center;

                                .discount {
                                    font-size: 15px;
                                    font-weight: 300;
                                    margin-left: 10px;
                                }
                            }
                        }

                        > a {
                            padding: 3px;
                            height: 30px;
                            width: 100%;
                            font-size: 16px;
                            margin-top: 5px;
                        }
                    }
                }

                &.description {
                    margin-top: 10px;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        height: calc(100dvh - 130px);

        > .container {
            margin: 15px;

            > div.form { margin-bottom: 0; }
        }
    }

    @media only screen and (max-height: 600px) and (max-width: 1100px) {
        > .container {
            > div {
                &.form { overflow-y: auto; }
                &.description { margin-top: 10px; }
            }
        }
    }
}