#overview {
    padding: 25px;
    overflow-y: auto;
    height: calc(100dvh - 80px);

    h3 {
        font-family: "Staatliches";
        color: rgb(32, 32, 32);
        font-weight: 400;
        font-size: 26px;
        margin-bottom: 15px;
    }

    .overall {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 20px;

        >.box {
            border-radius: 7px;
            padding: 10px 15px;
            background-color: white;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.061);
            transition: 200ms;

            &:hover {
                box-shadow: 0px 1px 8px 3px rgba(0, 0, 0, 0.061);
            }

            h5 {
                font-family: "Roboto Condensed";
                text-align: end;
                font-weight: 500;
                font-size: 17px;
            }

            p.value {
                font-family: "Poppins";
                display: flex;
                align-items: center;
                line-height: 65px;
                margin-top: 10px;

                >span {
                    font-family: "Poppins";
                    color: var(--orange);
                    font-size: 70px;
                }
            }

            .loader {
                display: block;
                margin: 0 auto;
                width: 40%;
            }
        }

        @media only screen and (max-width: 1500px) {
            grid-template-columns: 1fr 1fr;

            > .box {
                width: 100%;
                margin-bottom: 20px;

                &:last-child { grid-column: 1 / 3; }
            }
        }

        @media only screen and (max-width: 500px) {
            grid-template-columns: 1fr;

            > .box {
                &:last-child { grid-column: 1; }
            }
        }
    }

    .sites, .articles {
        margin-top: 50px;

        .container {
            background-color: white;
            border-radius: 15px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

            >p {
                font-family: "Roboto Condensed";
                text-align: center;
                font-size: 18px;
                padding: 30px 0;

                >a {
                    font-family: "Roboto Condensed";
                    color: var(--orange);
                }
            }
        }
    }

    .articles {
        .article {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 20px;
            border-bottom: 1px solid #F1F1F1;
            background-color: white;

            &:hover { background-color: rgb(250, 250, 250); }
            &:first-child { border-radius: 15px 15px 0 0; }
            &:last-child { border-radius: 0 0 15px 15px; }

            >a {
                width: 100%;

                .title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h3 {
                        font-family: "Roboto Condensed";
                        white-space: nowrap;
                        color: #000;
                        font-size: 25px;
                        font-weight: 600;
                    }

                    .keywords {
                        display: flex;
                        justify-content: flex-end;
                        flex-wrap: wrap;
                        padding: 10px 0 10px 20px;

                        span {
                            display: block;
                            background: var(--orange);
                            color: white;
                            margin-bottom: 5px;
                            margin-right: 5px;
                            padding: 2px 5px;
                            border-radius: 5px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            max-width: 270px;
                        }
                    }
                }

                .content {
                    padding: 5px 0 15px 0;

                    p {
                        color: #303030;
                        font-family: "Poppins";
                        font-size: 17px;
                        font-weight: 400;
                    }
                }
            }

            @media only screen and (max-width: 800px) {
                padding: 10px;

                >a {
                    .title {
                        flex-direction: column-reverse;
                        align-items: flex-start;

                        .keywords {
                            padding: 0;
                            justify-content: flex-start;
                        }
                    }

                    .content {
                        p {
                            display: -webkit-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
        }

        .loader {
            display: block;
            margin: 0 auto;
        }
    }

    .sites {
        .site {
            padding: 20px;

            &:hover { background-color: rgb(250, 250, 250); }
            &:not(:last-child) { border-bottom: 1px solid #F1F1F1; }

            a {
                width: fit-content;
                display: flex;
                align-items: center;

                img {
                    height: 25px;
                    margin-right: 10px;
                    border-radius: 2px;
                }
            }

            h4 {
                font-family: "Roboto Condensed";
                font-weight: 500;
                font-size: 18px;
            }
        }

        p.more {
            font-family: "Poppins";
            text-align: start;
            padding: 5px 20px;
            font-size: 15px;
        }
    }

    .templates {
        margin-top: 50px;

        >.container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 20px;
            row-gap: 20px;

            @media only screen and (max-width: 1070px) {
                grid-template-columns: 1fr;
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        height: calc(100dvh - 70px);
    }

    @media only screen and (max-width: 768px) {
        height: calc(100dvh - 50px);
    }

    @media only screen and (max-width: 600px) {
        height: calc(100dvh - 100px);
        padding: 15px;

        h3 {
            text-align: center;
        }
    }
}