#text-page {
    padding: 35px;

    h1, h2, h3, h4, h5, h6 {
        font-family: "Staatliches";
        font-weight: 500;
        margin-bottom: 10px;
    }

    h1 {
        font-size: 60px;
        margin-bottom: 30px;
    }
    h2 {
        font-size: 30px;
        margin-top: 50px;
    }
    h3 {
        font-family: "Roboto Condensed";
        font-weight: 600;
        font-size: 24px;
        margin-left: 10px;
    }

    p {
        font-family: "Poppins";
        font-size: 18px;
        margin-bottom: 30px;
        margin-left: 20px;

        &:nth-child(2),
        &:nth-child(3),
        &:last-child {
            margin-left: 5px;
        }
        &:last-child {
            margin-top: 50px;
        }

        b {
            font-weight: 600;
        }
    }

    ul {
        margin: 0;
        margin-left: 20px;

        li {
            font-family: "Poppins";
            font-size: 18px;
        }
    }

    @media only screen and (max-width: 768px) {
        h1 {
            text-align: center;
            font-size: 45px;
        }
    }
}