.template {
    background-color: white;
    color: var(--dark-orange);
    padding: 30px;
    font-size: 35px;
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    transition: 200ms;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &:hover {
        background-color: #fc86003b;
    }

    .content {
        display: flex;
        align-items: center;

        > .icon {
            border-radius: 12px;
            background-color: #fc86003b;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 60px;
            min-width: 60px;
            margin-right: 10px;

            svg path {
                stroke: var(--dark-orange);
            }
        }

        h2 {
            font-family: "Staatliches";
            font-size: 23px;
            font-weight: 500;
            color: black;
            display: flex;
            align-items: center;
    
            svg {
                margin-left: 10px;
                font-size: 17px;
                min-height: 20px;
                min-width: 20px;
                color: var(--dark-orange);

                @media only screen and (max-width: 400px) {
                    display: none;
                }
            }
        }
    }

    .mobile {
        display: none;
        height: 100%;
        align-items: flex-end;
    }

    p {
        font-family: "Poppins";
        font-size: 14px;
        color: rgb(103, 103, 103);
    }

    @media only screen and (max-width: 1620px) {
        padding: 15px;
        
        .text {
            width: 100%;

            p { display: none; }
        }

        .mobile {
            display: flex;
            
            p {
                margin-top: 10px;
            }
        }
    }

    @media only screen and (max-width: 1350px) {
        .content {
            h2 {
                font-size: 20px;
            }
        }
    }
}