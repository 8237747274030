.selection {
    position: absolute;
    display: block;
    background-color: white;
    z-index: 10;
    padding: 0 0 10px 0;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    min-width: 250px;
    width: 250px;
    overflow-x: hidden;

    &.visible { display: block; }

    .top {
        position: relative;
        display: flex;
        padding: 10px;

        input {
            font-family: "Poppins";
            background-color: rgb(238, 238, 238);
            outline: none;
            width: 100%;
            border-radius: 20px;
            padding: 5px 30px 5px 10px;
        }

        .icon {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 2px;
        }
    }

    .body {
        max-height: 300px;
        overflow-y: auto;

        span#empty {
            font-family: "Poppins";
            display: block;
            margin: 0 auto;
            width: fit-content;
            color: gray;
            font-size: 14px;
        }

        .item {
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: rgb(238, 238, 238);
            }
        }
    }
}