#announcement {
    width: 100%;
    color: white;
    height: 50px;

    > div {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        padding: 0 40px;
        background: linear-gradient(80deg, #1c1c1c, rgb(37, 37, 37));

        p {
            font-family: "Poppins";
            line-height: 16px;
            font-size: 18px;
        }
    
        svg {
            cursor: pointer;
        }
    }

    @media only screen and (max-width: 925px) {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 4;

        &.page-start {
            top: 0px;
        }

        > div { padding: 0 30px; }
    }

    @media only screen and (max-width: 500px) {
        > div {
            position: relative;
            padding: 0 10px;

            p {
                font-size: 15px;
                margin-right: 10px;
            }

            svg {
                font-size: 25px;
            }
        }
    }
}

header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 0 40px;
    height: 85px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.124);

    h1.title {
        font-size: 48px;
    }

    .links {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        a {
            font-size: 18px;
            margin-left: 50px;
            color: #817474;

            &:hover {
                color: #000;
            }

            &:first-child {
                margin: 0;
            }

            &.login {
                color: #FFF;
                font-family: "Roboto";
                font-weight: 500;
                padding: 10px 50px;
                border-radius: 24px;
                background: var(--orange);

                &:hover {
                    background: var(--dark-orange);
                }
            }
        }
    }

    .hamburger {
        display: none;
        cursor: pointer;
    }

    .mobile-menu {
        position: absolute;
        display: none;
        flex-direction: column;
        top: 84px;
        left: 0;
        width: 100%;
        padding: 30px;
        background-color: white;
        box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.11);
        z-index: 10;

        a {
            font-family: "Poppins";
            font-weight: 500;
            font-size: 20px;
            padding: 6px 0;

            &:last-child {
                color: var(--dark-orange);
            }
        }

        &.toggle {
            display: flex;
        }
    }

    @media only screen and (max-width: 925px) {
        position: fixed;
        top: 0;
        width: 100%;
        padding: 0 10px;
        height: 70px;
        z-index: 4;

        &.page-start {
            top: 50px;
        }
        
        .links {
            display: none;
        }

        .hamburger {
            display: block;
        }

        .mobile-menu {
            top: 70px;
        }
    }

    @media only screen and (max-width: 400px) {
        .mobile-menu {
            top: 69px;
        }
    }
}