#tickets {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 130px);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    margin: 25px;
    padding: 0 !important;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        font-family: "Roboto Condensed";
        color: #FFF;
        font-weight: 500;
        padding: 6px 20px;
        border-radius: 10px;
        min-width: 160px;
        font-size: 18px;
        margin: 0;
        background: var(--orange);

        &:hover {
            background: var(--dark-orange);
        }
    }

    .link-type {
        display: flex;
        align-items: center;
        background: transparent !important;
        color: #000;
        padding: 6px 0;
        min-width: fit-content;

        svg {
            margin-right: 6px;
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 75px;
        padding: 0 20px;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #F1F1F1;

        span:not(.link-type) {
            font-family: "Roboto Condensed";
            margin-right: 10px;
        }

        h2 {
            font-family: "Roboto Condensed";
            font-size: 25px;
            font-weight: 600;
        }

        @media only screen and (max-width: 768px) {
            .link-type span { display: none; }
        }

        @media only screen and (max-width: 630px) {
            span:not(.link-type) { visibility: hidden; }
        }

        @media only screen and (max-width: 500px) {
            span:not(.link-type) { display: none; }

            button {
                width: 100%;
                word-wrap: break-word;
                min-width: fit-content;
            }
        }
    }

    .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .tickets {
            display: flex;
            flex-direction: column;
            width: 100%;

            &.empty {
                align-items: center;
            }

            .empty-text {
                font-family: "Poppins";
                font-size: 20px;
                text-align: center;
                color: rgb(59, 59, 59);
                margin-top: 30px;

                a {
                    color: var(--orange);
                }
            }

            .ticket {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 135px;
                padding: 20px;
                border-bottom: 1px solid #F1F1F1;
                background-color: white;
                cursor: pointer;

                &:hover {
                    background-color: rgb(250, 250, 250);
                }

                &:nth-child(odd) {
                    background-color: rgb(252, 252, 252);

                    &:hover {
                        background-color: rgb(248, 248, 248);
                    }
                }

                &.active {
                    .status>.status-icon {
                        background: radial-gradient(circle, rgb(4, 167, 72) 0%, rgba(7, 158, 73, 1) 50%, rgba(0, 212, 255, 1) 100%);
                    }
                }

                &.closed {
                    // background-size: auto auto;
                    // background-color: rgba(255, 255, 255, 0);
                    // background-image: repeating-linear-gradient(135deg, transparent, transparent 22px, rgba(230, 230, 230, .4) 22px, rgba(230, 230, 230, .4) 32px );

                    .status>.status-icon {
                        background-color: gray;
                    }
                }

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    p {
                        font-family: "Poppins";
                        width: 60%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        color: rgb(59, 59, 59);
                    }

                    .notification {
                        display: none;

                        & + h4 {
                            color: #00ab60;
                        }
                    }

                    h4 {
                        font-family: "Roboto Condensed";
                        font-size: 20px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    .status {
                        font-family: "Roboto Condensed";
                        display: flex;
                        align-items: center;
                        margin-right: 10px;

                        .status-icon {
                            height: 14px;
                            width: 14px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                    }

                    .date {
                        font-family: "Roboto Condensed";
                        color: gray;
                        white-space: nowrap;
                    }
                }

                @media only screen and (max-width: 768px) {
                    justify-content: center;
                    height: 110px;

                    > div {
                        flex-wrap: wrap;

                        .status, .date {
                            margin-top: 10px;
                        }

                        p {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .create {
            flex: 1;
            display: flex;
            flex-direction: column;

            .form {
                padding: 20px;
                height: 100%;

                >div {
                    &.textarea-container {
                        height: 50%;
                        min-height: 200px;
                    }
                }

                h3 {
                    font-family: "Roboto Condensed";
                    font-weight: 600;
                    font-size: 26px;
                    margin-bottom: 5px;
                }

                p {
                    font-family: "Poppins";
                    font-weight: 400;
                    font-size: 14px;
                    margin-bottom: 10px;
                    color: rgb(59, 59, 59);
                }

                textarea {
                    font-family: "Poppins";
                    resize: vertical;
                    outline-color: var(--orange);
                    min-height: 100%;
                    width: 60%;
                    padding: 10px;
                    border: 2px solid rgba(128, 128, 128, 0.053);
                    border-radius: 10px;
                }

                input {
                    font-family: "Poppins";
                    outline-color: var(--orange);
                    height: 40px;
                    width: 60%;
                    padding: 0 10px;
                    border: 2px solid rgba(128, 128, 128, 0.053);
                    border-radius: 10px;
                    margin-bottom: 50px;
                }
            }

            @media only screen and (max-width: 1000px) {
                .form {
                    textarea, input {
                        width: 100%;
                    }
                }
            }
        }
    }

    .bottom {
        position: relative;
        display: flex;
        align-items: center;
        height: 55px;
        border-top: 1px solid #F1F1F1;
        padding: 0 10px;

        @media only screen and (max-width: 768px) {
            button {
                width: 100%;
            }
        }
    }

    #ticket {
        height: 100%;
        display: flex;
        flex-direction: column;

        .top {
            h2 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 90%;
            }

            @media only screen and (max-width: 768px) {
                padding: 0 10px;

                .link-type {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    height: 30px;
                    width: 30px;
                    min-height: 30px;
                    min-width: 30px;
                    max-height: 30px;
                    max-width: 30px;
                    color: white;
                    background: var(--orange) !important;

                    svg {
                        margin: 0;
                    }
                }
            }
        }

        .body {
            flex: 1;
            overflow-y: auto;

            .messages {
                padding: 15px;
    
                .message {
                    font-family: "Poppins";
                    width: 60%;
                    border: 1px solid rgb(228, 228, 228);
                    border-radius: 15px;
                    padding: 20px;
                    margin-bottom: 15px;
                    font-size: 18px;
                    overflow-wrap: break-word;

                    &:last-child {
                        &.user { border-radius: 15px 15px 0 15px; }
                        &.admin { border-radius: 15px 15px 15px 0; }
                        margin-bottom: 0;
                    }
                    &:first-child {
                        &.user { border-radius: 15px 0 15px 15px; }
                        &.admin { border-radius: 0 15px 15px 15px; }
                    }
    
                    .info {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-end;
                        width: 100%;
                        margin-top: 20px;
    
                        span {
                            font-family: "Roboto Condensed";
                            color: rgb(83, 83, 83);
                            font-size: 14px;
                            margin: 0 10px;
                        }
    
                        svg {
                            font-size: 20px;
                        }
                    }
    
                    &.user {
                        float: right;
                        // display: flex;
                        // flex-direction: column;
                        // align-items: flex-end;
                        background-color: rgba(228, 228, 228, 0.747);
                    }
    
                    &.admin {
                        float: left;
                        background-color: rgba(206, 226, 243, 0.747);
                    }

                    @media only screen and (max-width: 440px) {
                        padding: 10px;
                        font-size: 16px;

                        .info {
                            flex-direction: row-reverse;

                            span, svg {
                                margin: 0 4px;
                            }
                        }
                    }
                }
            }
        }
        
        .bottom {
            input {
                font-family: "Roboto Condensed";
                font-size: 16px;
                width: 100%;
                height: 35px;
                border-radius: 20px;
                background-color: rgb(238, 238, 238);
                padding: 0 30px 0 20px;
                outline: none;
            }

            svg {
                color: #1c1c1c;
                position: absolute;
                right: 20px;
                cursor: pointer;
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        height: calc(100dvh - 120px);
    }

    @media only screen and (max-width: 768px) {
        height: calc(100dvh - 100px);
    }

    @media only screen and (max-width: 600px) {
        margin: 15px;
        height: calc(100dvh - 130px);
    }
}