#not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0 0 0;

    img {
        width: 500px;
    }

    h1 {
        font-family: "Staatliches";
        font-size: 100px;
        font-weight: 500;
        margin-top: 30px;
        text-align: center;
    }

    p {
        font-family: "Poppins";
        font-size: 20px;
        margin: 0 20px;
        text-align: center;
    }

    @media only screen and (max-width: 768px) {
        padding: 50px;

        h1 {
            font-size: 90px;
        }
    }

    @media only screen and (max-width: 500px) {
        img {
            width: 90%;
        }
    }
}