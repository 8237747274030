.credits {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    svg {
        padding: 2px;
        font-size: 23px;
        margin-left: 8px;
        color: var(--dark-orange);
    }

    span.credits-text {
        font-family: "Poppins";
        font-size: 18px;
        transition: 50ms;

        &:hover {
            color: var(--dark-orange);
        }
    }
}