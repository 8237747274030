#confirmation-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.45);

    .content {
        background: white;
        box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 40%;

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: fit-content;
            padding: 15px;

            h4 {
                font-family: "Roboto Condensed";
                font-weight: 600;
                font-size: 23px;
            }

            svg {
                height: 15px;
                cursor: pointer;
            }
        }

        .body {
            padding: 25px;

            p {
                font-family: "Poppins";
                font-size: 20px;
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                margin-top: 30px;

                button {
                    outline: none;
                    font-family: "Roboto Condensed";
                    color: #FFF;
                    font-weight: 500;
                    padding: 6px 20px;
                    margin-left: 10px;
                    border-radius: 10px;
                    min-width: 160px;
                    font-size: 18px;
                    background: var(--orange);
        
                    &:hover {
                        background: var(--dark-orange);
                    }

                    &.gray {
                        background: rgb(155, 155, 155);
        
                        &:hover {
                            background: rgb(133, 133, 133);
                        }
                    }

                    &.red {
                        background: red;
        
                        &:hover {
                            background: rgb(204, 2, 2);
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 1300px) {
            width: 60%;
        }

        @media only screen and (max-width: 768px) {
            width: 90%;
        }

        @media only screen and (max-width: 500px) {
            .body {
                padding: 15px;

                p {
                    font-size: 17px;
                    text-align: center;
                }

                .buttons {
                    flex-wrap: wrap;

                    button {
                        width: 100%;
                        margin: 0;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}