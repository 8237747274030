#templates {
    height: 100%;
    overflow-y: auto;

    > h2 {
        font-family: "Staatliches", sans-serif;
        font-weight: 400;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-size: 26px;
        text-transform: uppercase;
        color: rgb(40, 40, 40);

        &::after {
            content: '';
            height: 2px;
            width: calc(100% - 20px);
            margin-left: 20px;
            background-color: rgba(119, 52, 0, 0.04);
            display: block;
        }
    }

    > section {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 30px;
        column-gap: 30px;
        margin: 10px 0 30px 0;

        &:last-child { margin-bottom: 0; }

        &.seo {
            .template {
                $bg: rgba(128, 0, 128, 0.151);
                &:hover { background-color: $bg }
    
                .icon {
                    background-color: $bg;
                    > svg, > svg path {
                        color: purple;
                        stroke: purple;
                    }
                }
            }
        }

        &.titles {
            .template {
                $bg: rgba(11, 133, 7, 0.151);
                &:hover { background-color: $bg }
    
                .icon {
                    background-color: $bg;
                    > svg, > svg path {
                        color: green;
                        stroke: green;
                    }
                }
            }
        }

        &.other {
            .template {
                $bg: rgba(10, 113, 203, 0.233);
                &:hover { background-color: $bg }
    
                .icon {
                    background-color: $bg;
                    > svg, > svg path {
                        color: rgb(5, 14, 151);
                        stroke: rgb(5, 14, 151);
                    }
                }
            }
        }

        @media only screen and (max-width: 1500px) {
            column-gap: 15px;
            row-gap: 15px;
        }

        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            row-gap: 30px;
        }
    }

    .template-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    >div:first-child.warnbox {
        grid-column: 1 / -1;
    }

    @media only screen and (max-width: 1100px) {
        height: calc(100dvh - 70px);
    }

    @media only screen and (max-width: 768px) {
        height: calc(100dvh - 50px);
    }

    @media only screen and (max-width: 600px) {
        height: calc(100dvh - 100px);
    }

    @media only screen and (max-width: 500px) {
        row-gap: 15px;
    }
}