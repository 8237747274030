#dashboard-header {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    background-color: white;
    font-family: "Poppins";

    .page {
        h2 {
            font-family: "Staatliches";
            font-weight: 500;
            font-size: 26px;
        }
    }

    .info {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        
        .welcome {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 200px;
            font-size: 19px;
            margin-right: 20px;
            padding-right: 20px;
            border-right: 2px solid rgba(0, 0, 0, 0.2);
        }
    }

    @media only screen and (max-width: 1100px) {
        height: 70px;
    }

    @media only screen and (max-width: 768px) {
        height: 50px;
    }

    @media only screen and (max-width: 500px) {
        padding: 0 10px;

        .page { display: none; }
        .info {
            width: 100%;
            justify-content: space-between;

            .welcome {
                padding: 0;
                border: none;
            }
        }
    }
}