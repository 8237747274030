#home-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 120px 0 240px 0;
    margin-bottom: -200px;
    background: rgb(252,136,0);
    background: linear-gradient(180deg, rgb(255, 94, 0) 0%, rgb(252, 109, 0) 100%);

    > h2 {
        font-family: "Roboto Condensed";
        color: #fff;
        text-align: center;
        font-size: 80px;
        font-weight: 700;
        margin: 0 10px 24px 10px;
    }

    > p {
        font-family: "Poppins";
        color: rgb(247, 247, 247);
        text-align: center;
        font-size: 25px;
        font-weight: 400;
    }

    > a {
        margin-top: 100px;
        font-weight: 500;
        padding: 10px 50px;
        border-radius: 24px;
        font-size: 22px;
        background: white;
        color: var(--orange);
        text-align: center;

        &:hover {
            color: var(--dark-orange);
            box-shadow: 0 0 20px 5px #00000027;
        }
    }

    .wave {
        position: absolute;
        bottom: -450px;
        width: max(2040px, 100%);
    }

    @media only screen and (max-width: 768px) {
        padding: 90px 20px;
        margin-bottom: 0;

        > h2 {
            font-size: 60px;
        }

        > .wave {
            display: none;
        }
    }

    @media only screen and (max-width: 600px) {
        padding: 80px 10px 60px 10px;
        
        > h2 {
            font-size: 40px;
        }

        > p {
            font-size: 20px;
        }

        > a {
            margin-top: 40px;
        }
    }
}