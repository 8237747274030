#confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 150px 0 100px 0;

    h1 {
        font-family: "Roboto Condensed";
        text-transform: uppercase;
        font-size: 30px;
        margin: 0 10px;
        margin-top: 30px;
        text-align: center;
    }

    p {
        font-family: "Poppins";
        text-align: center;
        font-size: 15px;
        margin-top: 15px;
    }

    @media only screen and (max-width: 500px) {
        padding: 50px ;
    }
}