#login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0 0 0;

    > .content {
        display: flex;
        align-items: center;
        background-color: white;
        width: 60%;
        box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.101);
        border-radius: 7px;

        > div {
            max-height: 550px;

            h3 {
                font-family: "Staatliches";
                display: none;
                width: 100%;
                text-align: center;
                font-weight: 500;
                font-size: 42px;
                margin-bottom: 30px;
            }

            h4 {
                font-family: "Roboto Condensed";
                width: 100%;
                font-size: 30px;
                margin-bottom: 10px;
            }
            
            &.text {
                position: relative;
                display: flex;
                justify-content: center;
                flex-direction: column;
                background: url('../../assets/img/login-text-bg.png');
                height: 550px;
                width: 550px;
                min-width: 550px;
                padding: 30px;
                border-radius: 0 7px 7px 0;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(255, 255, 255, 0.285);
                    z-index: 0;
                }

                > * {
                    position: relative;
                    z-index: 1;
                }

                h2 {
                    font-family: "Staatliches";
                    font-weight: 500;
                    font-size: 39px;
                }

                p {
                    font-family: "Poppins";
                    font-weight: 500;
                    font-size: 20px;
                }
            }

            &.form {
                width: 100%;
                padding: 50px;

                > div {
                    display: flex;
                    flex-direction: column;
                    max-width: 80%;

                    &.loading {
                        max-width: 100%;
                        justify-content: center;
                        align-items: center;
                    }

                    .links {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    span {
                        font-family: "Roboto Condensed";
                        margin-bottom: 10px;
                        padding-left: 10px;
                    }

                    input {
                        margin-bottom: 20px;
                        outline-color: var(--orange);
                        height: 40px;
                        padding: 0 10px;
                        border: 2px solid rgba(128, 128, 128, 0.053);
                        border-radius: 10px;
                    }

                    button {
                        font-family: "Roboto Condensed";
                        font-size: 20px;
                        padding: 6px 50px;
                        border-radius: 12px;
                        display: block;
                        width: fit-content;
                        background-color: var(--orange);
                        color: white;
                        outline: none;

                        &:hover {
                            background-color: var(--dark-orange);
                        }
                    }

                    &.login-container {
                        > div {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 40px;
                        }

                        p {
                            color: var(--dark-orange);
                            text-align: center;
                            cursor: pointer;
                        }
                    }

                    &.register-container {
                        > div {
                            display: flex;
                            align-items: center;

                            input {
                                margin: 0;
                            }

                            p {
                                text-align: start;
                                margin-left: 10px;

                                a {
                                    color: var(--dark-orange);
                                    text-decoration: underline;
                                }
                            }

                            &:not(:last-child) {
                                p { font-size: 14px; }
                            }

                            &:last-child {
                                justify-content: space-between;
                                margin-top: 40px;

                                p {
                                    color: var(--dark-orange);
                                    text-align: center;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    &.reset-password-container {
                        p {
                            color: var(--dark-orange);
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1760px) {
        > .content {
            width: 80%;
        }
    }

    @media only screen and (max-width: 1320px) {
        > .content {
            width: 90%;

            > div.form > div {
                max-width: 100%;
            }
        }
    }

    @media only screen and (max-width: 1060px) {
        > .content {
            > div {
                max-height: fit-content;

                h3 {
                    display: block;
                }

                &.text {
                    display: none;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        > .content {
            > div.form > div {
                > div {
                    &.links {
                        flex-wrap: wrap;

                        button, p {
                            width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                }

                &.login-container > div { margin-bottom: 0; }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        padding: 20px 0 0 0;

        > .content {
            > div {
                h3 {
                    font-size: 30px;
                }

                &.form {
                    padding: 20px 10px;
                }
            }
        }
    }
}