#pricing {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;

    h2 {
        font-family: "Staatliches";
        font-size: 60px;
        font-weight: 500;
        text-align: center;
        padding: 0 20px;
    }

    > p {
        font-family: "Poppins";
        font-size: 20px;
        padding: 0 20px;
        text-align: center;

        .brand {
            font-size: 20px;
        }
    }

    .calculator {
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        width: 80%;
        margin-top: 50px;
        
        > section {
            display: grid;
            column-gap: 20px;
            width: 50%;
            color: white;
            padding: 30px;
            margin: 0 10px;
            border-radius: 15px;
            background: #232630;
            opacity: 0.90;
            transition: 200ms;

            &:hover {
                opacity: 1;
            }

            &:nth-child(2) {
                background: var(--dark-orange);
            }

            h4 {
                font-family: "Roboto Condensed";
                font-size: 24px;
            }

            p {
                font-family: "Poppins";
                font-size: 13px;
            }

            > div.input {
                display: flex;
                align-items: center;
                margin-top: 20px;

                input {
                    font-family: "Roboto Condensed";
                    font-size: 17px;
                    outline-color: var(--orange);
                    height: 33px;
                    width: 100%;
                    padding: 0 10px;
                    border: 2px solid rgba(128, 128, 128, 0.053);
                    border-radius: 10px;
                    outline: none;
                    background-color: white;
                }

                > span {
                    display: block;
                    white-space: nowrap;
                    margin-left: 10px;
                    font-size: 17px;

                    .brand {
                        font-weight: 400;
                        font-size: 17px;

                        .orange-text {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .result {
        margin-top: 50px;
        padding: 20px;
        box-shadow: 0 0 30px 2px #ebebeb4d;
        border-radius: 15px;

        p {
            font-family: "Roboto Condensed";
            font-size: 20px;
            padding: 0 20px;
            text-align: center;

            .brand {
                font-size: 20px;
            }

            b {
                font-weight: 600;
                color: var(--dark-orange);
            }
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 150px 10px 0 10px;
        padding: 10px 30px;
        border-radius: 15px;
        color: white;
        background: linear-gradient(to bottom, var(--orange), var(--dark-orange));

        ul {
            margin-top: 0;
            padding: 0;
            display: flex;
            align-items: center;
            flex-direction: column;

            li {
                font-family: "Poppins";
                font-size: 19px;
                list-style: none;
                text-align: center;
                padding: 10px;
            }
        }

        @media only screen and (max-width: 768px) {
            margin-top: 30px;
        }
    }

    .wave {
        position: absolute;
        z-index: -1;
        width: 100%;
        top: -300px;
        opacity: 0.4;
    }

    @media only screen and (max-width: 1300px) {
        .calculator {
            width: 100%;
        }

        .result {
            p {
                text-align: center;
                padding: 0 20px;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .wave {
            top: -200px;
        }
    }

    @media only screen and (max-width: 768px) {
        margin-top: 50px;
        
        .calculator {
            flex-direction: column;
            padding: 20px;
            margin-top: 0px;

            > section {
                margin: 20px 0 0 0;
                width: 100%;
            }
        }

        .result {
            margin-top: 0px;
        }

        .wave {
            top: -150px;
        }
    }

    @media only screen and (max-width: 600px) {
        .wave {
            top: -100px;
        }
    }

    @media only screen and (max-width: 500px) {
        h2 {
            font-size: 40px;
            margin-bottom: 0;
        }

        p, p .brand {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 470px) {
        .wave {
            top: -50px;
        }
    }
}