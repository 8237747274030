footer {
    display: flex;
    flex-direction: column;
    background: white;
    // margin-top: 100px;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.04);

    > div {
        width: 100%;

        &.top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px;
            font-family: "Poppins";
            max-width: 2048px;
            margin: 0 auto;

            > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0 10px;

                &.about {
                    align-items: center;
                    flex-direction: row;
                    
                    .image {
                        display: none;
                        margin-right: 20px;

                        img {
                            height: 150px;
                            width: 150px;
                        }
                    }

                    .text {
                        .brand, p {
                            width: 80%;

                            a {
                                color: var(--orange);
                                font-weight: 500;
                            }

                            b {
                                font-weight: 500;
                            }
                        }
                    }
                }

                &.pages {
                    width: fit-content;

                    h6 {
                        font-family: "Roboto Condensed";
                        font-size: 24px;
                        width: 100%;
                    }

                    > div {
                        display: flex;
                        width: 100%;

                        ul {
                            margin-right: 30px;
                            margin-bottom: 0;
                            padding: 0;
    
                            li {
                                list-style: none;
                                white-space: nowrap;
                                margin-top: 5px;

                                a {
                                    font-family: "Poppins";
                                    color: #1c1c1c;
                                }
    
                                > a:hover {
                                    color: var(--orange);
                                }
                            }
                        }
                    }
                }

                &.tags {
                    width: 30%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    flex-wrap: wrap;

                    span {
                        display: inline-block;
                        background: var(--orange);
                        margin: 4px;
                        padding: 2px 5px;
                        border-radius: 4px;
                        transition: 200ms;
                        color: white;
                        white-space: nowrap;
                        cursor: pointer;

                        &:hover {
                            background: var(--dark-orange);
                        }
                    }
                }
            }
        }

        &.bottom {
            background-color: var(--orange);
            padding: 3px 10px;

            p {
                font-family: "Roboto Condensed";
                color: white;
                font-size: 18px;
                text-align: center;
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        > div.top {
            div.tags {
                width: 40%;
            }
            div.pages {
                width: 40%;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        > div.top {
            > div.about > div.image { display: none; }

            > div.tags {
                width: 25%;

                span {
                    font-size: 14px;
                }
            }

            > div.pages {
                width: 60%;

                > div ul li a {
                    font-size: 13px;
                }
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        > div.top {
            > div.about {
                width: 100%;
            }

            > div.pages {
                width: 100%;

                h6 {
                    text-align: end;
                    padding-right: 30px;
                }

                > div {
                    justify-content: flex-end;
                }
            }
            
            div.tags {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 900px) {
        > div.top {
            flex-direction: column;

            > div {
                width: 100% !important;
                text-align: center;
                display: flex;
                justify-content: center;
                margin: 0;
                margin-bottom: 40px;

                &.pages {
                    h6 {
                        text-align: center;
                        padding-right: 0;
                    }

                    > div {
                        justify-content: center;
    
                        ul {
                            margin: 15px 10px 0 10px;
    
                            li a {
                                font-size: 17px;
                            }
                        }
                    }
                }

                &.tags {
                    display: block;

                    span {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        padding-top: 20px;
        // margin-top: 20px;

        div.pages {
            > div {
                flex-direction: column;

                ul {
                    padding-bottom: 20px !important;
                    border-bottom: 1px solid rgb(219, 219, 219);
                }
            }
        }
    }

    @media only screen and (max-width: 400px) {
        > div.top {
            padding: 10px;
        }
    }

    @media only screen and (max-width: 300px) {
        > div.top {
            div.tags { display: none; }
        }
    }
}